<template>
	<div class="header d-flex flex-nowrap justify-start align-start width-100 pl-0">
		<v-btn @click="click" icon small :ripple="false" plain color="var(--high) !important">
			<v-icon size="14" class="mt-4" :class="{ rotated: value }">
				far
				{{ value ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</v-btn>
		<div class="width-100">
			<!-- <v-hover v-slot="{ hover }"> -->
			<div
				class="width-100 pa-1 rounded"
				style="background-color: var(--fondo-1)"
				:ripple="false"
				@click="
					() => {
						if ($store.getters.getPermissions['APP_DRAW_AREA']) toggleSelection(areaId);
					}
				"
				:title="$t('panels.notifications.clickToCenterArea')"
				flat
			>
				<div
					class="header-title fs-regular d-flex align-center mt-2 color-black div-spacing"
					:class="{ disabled, selected: isSelected }"
					style="width: 100%; height: 20px"
					:style="isSelected ? { border: `2px solid ${selectedColor}`, backgroundColor: selectedColor } : {}"
				>
					<div class="flex-grow-1">
						{{ title }}
					</div>
					<div class="ml-auto">{{ total }}</div>
				</div>

				<div class="kpibar d-flex justify-start" style="border-radius: 2px">
					<slot />
				</div>
			</div>
			<!-- </v-hover> -->
			<v-expand-transition>
				<slot name="expand" v-bind="{ value }" />
			</v-expand-transition>
		</div>
	</div>
</template>

<script>
import { adjustNotificationsHeight } from '@/api/common';
import _debounce from 'debounce';
import AreaMixin from '@/mixins/AreaMixin';

export default {
	mixins: [AreaMixin],
	props: {
		value: {},
		disabled: {
			type: Boolean,
			default: false
		},
		title: {
			type: String
		},
		areaId: {
			type: String
		},
		total: {
			type: Number
		}
	},
	data() {
		return {
			selectedColor: 'var(--azul-select)'
		};
	},
	methods: {
		click() {
			this.$emit('input', !this.value);
			_debounce(adjustNotificationsHeight(this.$store.state.data), 300);
		},
		toggleSelection(areaId) {
			if (this.selectedAreas.find(id => id == areaId)) {
				this.$store.commit('removeSelectedArea', areaId);
			} else {
				this.$store.commit('addSelectedArea', areaId);
			}
			this.centerAreaMap(areaId);
		}
	},
	computed: {
		selectedAreas() {
			return this.$store.getters.getSelectedAreas;
		},
		isSelected() {
			return !!this.$store.getters.getSelectedAreas.find(id => id == this.areaId);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.v-card.selected {
	border-radius: 5px;
}
.div-spacing {
	padding: 8px;
}
.header {
	color: var(--high);

	& .icon {
		transform-origin: center center;
	}

	& .rotated {
		transform: rotate(-180deg);
	}

	& .header-title {
		transition: color 0.2s ease-in !important;
		cursor: pointer;
	}

	& .disabled {
		color: var(--moderate);
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
