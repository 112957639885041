import { render, staticRenderFns } from "./PanelNotificationsDetail.vue?vue&type=template&id=2d111e10"
import script from "./PanelNotificationsDetail.vue?vue&type=script&lang=js"
export * from "./PanelNotificationsDetail.vue?vue&type=script&lang=js"
import style0 from "./PanelNotificationsDetail.vue?vue&type=style&index=0&id=2d111e10&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports