<template>
	<pui-modal-dialog
		v-if="dialogModel"
		:widthDialog="1200"
		:heightDialog="750"
		titleText
		messageText
		:okText="$t('common.close')"
		dialogName="detailNotificationDialogHistoric"
		ref="detailNotificationDialogHistoric"
		:scrollable="true"
	>
		<template slot="message">
			<div>
				<v-card-title class="pa-3 fs-xl">{{ this.$t('panels.detailNotification.historicNotifications') }}</v-card-title>
				<pui-datatable
					:modelName="model"
					:modalDialog="true"
					:navigableDetail="false"
					:showCreateBtn="false"
					:showDeleteBtn="false"
					:pageLength="11"
					:lengthMenu="[5, 11, 20, 50, 100]"
					:externalFilter="externalFilter"
					:modelColumnDefs="columnDefs"
					:masterDetailColumnVisibles="masterDetailColumnVisibles"
					:showRowActions="false"
					:showActionsBtn="false"
					:showFilterListBtn="false"
					:readOnly="true"
				>
				</pui-datatable>
				<notification-historic-dialog
					v-show="notificationSource"
					:source="notificationSource"
					:deviceObject="notificationSource"
					:dialog.sync="visible"
					@update:historicDialog="visible = false"
					className="historic-dialog"
				></notification-historic-dialog>
			</div>
		</template>
	</pui-modal-dialog>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import { isSuperAdmin, formatTimestamp } from '@/api/common';

import NotificationHistoricDialog from '@/components/map/sections/map/subcomponents/notification/NotificationHistoricDialog.vue';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'HistoricNotifications',
	components: { NotificationHistoricDialog },
	props: {
		dialog: {
			default: false
		}
	},
	data() {
		return {
			model: 'historicalnotifications',
			dialogModel: false,
			visible: false,
			masterDetailColumnVisibles: {
				device: true,
				rulename: true,
				startdate: true,
				enddate: true,
				isaverage: true,
				ismobile: true,
				source: true
			},
			columnDefs: {
				rulename: {
					createdCell: (td, cellData, rowData) => {
						let ruleName = this.getRuleName(cellData);
						return (td.innerHTML = `<label class="fw-500" style="border-radius:4px;padding:2px 4px;background-color:${rowData.color + '99'};color:black">${ruleName}</label>`);
					}
				},
				startdate: {
					render: (data) => {
						return formatTimestamp(data, this.userProperties);
					}
				},
				enddate: {
					render: (data) => {
						return formatTimestamp(data, this.userProperties);
					}
				},
				source: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						td.id = 'source_global_' + rowData.historicalnotificationsid;
						td.classList.add('source-cell', 'd-flex', 'justify-center', 'pa-1');
						let inputSource = document.createElement('button');
						inputSource.classList.add('source-btn', 'pa-1', 'v-btn');
						inputSource.addEventListener('click', this.showSource, false);
						inputSource.sourceInfo = JSON.parse(cellData);
						// icon test
						let inputIcon = document.createElement('i');
						inputIcon.classList.add('fas', 'fa-chart-bar', 'fs-regular');
						inputSource.append(inputIcon);
						inputSource.innerHTML = '<i class="fas fa-chart-bar"></i>'; /*  + this.$t('grid.historicalnotifications.source'); */
						td.innerHTML = '';
						td.appendChild(inputSource);
						return;
					}
				},
				isaverage: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				ismobile: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			isSuperAdmin: false,
			notificationSource: {}
		};
	},
	watch: {
		dialog() {
			this.dialogModel = this.dialog;
		}
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmorganizationid == this.userProperties.organizationid);
		},
		selectedTabNotificationDialog: {
			get() {
				return this.$store.getters.getSelectedTabNotificationDialog;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotificationDialog', value);
			}
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.$puiEvents.$on('pui-modalDialog-detailNotificationDialogHistoric-ok', () => {
			this.dialogModel = false;
			this.$emit('update:dialog', false);
		});
	},
	mounted() {
		this.setExternalFilter();
		/* if (!this.isSuperAdmin) {
			const modalHeadlines = document.getElementsByClassName('headline');
			console.log('debug modalHeadlines', modalHeadlines);
			if (modalHeadlines.length > 0) {
				for (let i = 0; i < modalHeadlines.length; i++) {
					modalHeadlines[i].remove();
				}
			}
		} */
	},
	updated() {
		/* const modalHeadlines = document.getElementsByClassName('headline');
		console.log('debug modalHeadlines', modalHeadlines);
		if (modalHeadlines.length > 0) {
			for (let i = 0; i < modalHeadlines.length; i++) {
				modalHeadlines[i].remove();
			}
		} */
	},
	destroyed() {
		console.info('destroyed histNotifications');
		this.$puiEvents.$off('pui-modalDialog-detailNotificationDialogHistoric-ok');
	},
	methods: {
		showSource(evt) {
			console.log('Source: ', evt.currentTarget.sourceInfo);
			this.notificationSource = evt.currentTarget.sourceInfo;

			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == this.notificationSource['rule_name']);
			console.log('rule', rule);
			if (rule) {
				let filteredPollutants = this.pollutants.filter((pollutant) => pollutant.pmdatasourcetypeid == rule.pmdatasourcetypeid);
				let rulePollutant = filteredPollutants.find((pollutant) => pollutant.elasticacronym == rule.filterparameter);
				this.selectedTabNotificationDialog = filteredPollutants.indexOf(rulePollutant);
				this.visible = true;
			} else {
				this.$puiNotify.info('Please try again later', 'Not available');
				evt.currentTarget.disabled = true;
				evt.currentTarget.classList.add('disabled');
				console.log('evt', evt.currentTarget);
			}
		},
		getRuleName(ruleName) {
			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == ruleName);
			if (rule) return rule.name;
			return ruleName;
		},
		setExternalFilter() {
			let ruleNames = this.$store.getters.getRules.map((obj) => obj.acronym);
			this.externalFilter = null;
			if (ruleNames.length > 0) {
				if (this.isSuperAdmin) {
					this.externalFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.externalFilter.groups.push({
							groupOp: 'and',
							rules: [{ field: 'rulename', op: 'eq', data: rule }]
						});
					});
				} else {
					this.externalFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.externalFilter.groups.push({
							groupOp: 'and',
							rules: [
								{ field: 'rulename', op: 'eq', data: rule },
								{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }
							]
						});
					});
				}
			} else {
				if (!this.isSuperAdmin) {
					this.externalFilter = {
						groupOp: 'and',
						rules: [{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }],
						groups: []
					};
				}
			}
		}
	}
};
</script>

<style scoped>
.title {
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	color: #414141;
	margin-top: 10px;
}
</style>
